import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TxKeyPath } from 'src/i18n'
import { colors } from 'src/theme/colors'
import Link from 'next/link'
import LoadingSpinner from 'src/components/Common/LoadingSpinner'
import {
  RightAccessoryWrapper,
  LeftAccessoryWrapper,
  Wrapper,
  WrapperProps,
  StyledLink,
  StyledButton,
} from './styles'

export type ButtonPresets = keyof typeof presets

interface ButtonProps {
  labelTx?: TxKeyPath
  label?: string
  preset: ButtonPresets
  type?: 'button' | 'submit'
  isLink?: boolean
  href?: string
  LeftAccessory?: React.ReactNode
  RightAccessory?: React.ReactNode
  onClick?: () => void
  hasIconAndText?: boolean
  isLoading?: boolean
  disabled?: boolean
  target?: string
  className?: string
}

function ButtonComponent({
  LeftAccessory,
  RightAccessory,
  isLoading = false,
  disabled,
  ...props
}: ButtonProps) {
  const { t } = useTranslation()

  const labelText = props?.label || t(props?.labelTx)
  const presetStyles = presets[props.preset]

  const Content = () => (
    <>
      {!!LeftAccessory && (
        <LeftAccessoryWrapper isMarginL={props.hasIconAndText}>
          {LeftAccessory}
        </LeftAccessoryWrapper>
      )}
      {labelText}
      {!!RightAccessory && (
        <RightAccessoryWrapper isMarginL={props.hasIconAndText}>
          {RightAccessory}
        </RightAccessoryWrapper>
      )}
    </>
  )

  const RenderContent = () => (isLoading ? <LoadingSpinner /> : <Content />)

  const ButtonContent = () => (
    <Wrapper
      {...presetStyles}
      disabled={disabled}
      onClick={props.onClick}
      className={props.className}
      id="container-button-wrapper"
    >
      <RenderContent />
    </Wrapper>
  )

  return (
    <>
      {props?.isLink === true ? (
        <Link legacyBehavior passHref href={props?.href || '/'}>
          <StyledLink target={props.target}>
            <ButtonContent />
          </StyledLink>
        </Link>
      ) : (
        <StyledButton
          type={props?.type || 'button'}
          disabled={disabled ?? isLoading}
          className="button-component"
        >
          <ButtonContent />
        </StyledButton>
      )}
    </>
  )
}

export const Button = memo(ButtonComponent)

interface Presets {
  [key: string]: WrapperProps
}

const presets: Presets = {
  primary: {
    backgroundColor: colors.palette.primary600,
    color: colors.white,
  },
  secondary: {
    backgroundColor: colors.palette.secondary600,
    color: colors.white,
  },
  secondaryBg: {
    css: {
      color: colors.palette.secondary600,
      backgroundColor: 'transparent',
      border: `3px solid ${colors.palette.secondary600}`,
      'font-weight': '600',
    },
  },
  green: {
    backgroundColor: colors.palette.green400,
    color: colors.white,
  },
  danger: {
    backgroundColor: colors.palette.red500,
    color: colors.white,
  },
  neutral: {
    backgroundColor: colors.palette.neutral300,
    color: colors.palette.primary600,
  },
  form: {
    backgroundColor: colors.palette.green400,
    color: colors.white,
  },
  actionL: {
    color: colors.primaryTextColor,
    border: `1px solid ${colors.palette.neutral400}`,
    borderRadius: '0',
    backgroundColor: colors.white,
    borderBottomWidth: '0',
  },
  actionR: {
    borderLeft: `0`,
    color: colors.primaryTextColor,
    border: `1px solid ${colors.palette.neutral400}`,
    borderRadius: '0',
    backgroundColor: colors.white,
    borderBottomWidth: '0',
  },
  transparent: {
    backgroundColor: 'transparent',
    color: colors.secondaryTextColor,
  },
  lessonConclude: {
    css: {
      color: colors.white,
      backgroundColor: colors.palette.green400,
      'align-items': 'flex-start',
      padding: '1rem 1rem',
    },
  },
  favorite: {
    css: {
      color: colors.white,
      backgroundColor: colors.palette.stars,
      'align-items': 'flex-start',
      padding: '1rem 1rem',
    },
  },
  cancelFavorite: {
    css: {
      color: colors.palette.stars,
      border: `1px solid ${colors.palette.stars}`,
      'align-items': 'flex-start',
      padding: '1rem 1rem',
    },
  },
  cancelView: {
    css: {
      color: colors.palette.green400,
      border: `1px solid ${colors.palette.green400}`,
      'align-items': 'flex-start',
      padding: '1rem 1rem',
    },
  },
  exerciseDownload: {
    color: colors.white,
    backgroundColor: colors.secondaryBackgroundColor,
  },
}

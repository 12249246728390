import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { device } from 'src/theme/theme'

interface WrapperProps {
  justify: string
}

interface TitleProps {
  hasBorder: boolean
  textAlign: string
  borderMargin: string
  marginTitle: string
  borderColor: string
  size: string
  marginMobile: string
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${p => p.justify};
`

export const StyledTitle = styled.h2<TitleProps>`
  text-align: ${p => p.textAlign};

  span {
    display: inline-block;
    margin: ${({ marginMobile }) => marginMobile};
    color: ${p => p.color};
    ${({ theme, size }) => ({ ...theme.size[size] })};
    ${({ theme }) => ({ ...theme.typography.primary.medium })};
  }

  @media ${device.laptop} {
    margin: ${({ marginTitle }) => marginTitle};
  }

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }



  @media ${device.laptop} {
    span {
      margin-left: 0;
    }
  }

  &::after {
    ${p =>
      p.hasBorder &&
      `
        content: '';
        display: block;
        width: 60%;
        height: 3px;
        margin-right: auto;
        margin-left: auto;
        margin-top: ${p.theme.spacing.small};
        margin-bottom: ${p.theme.spacing[p.borderMargin]};
        background-color: ${p.borderColor};
      `}
  }

  &.light {
    color: ${({ theme }) => theme.colors.white};
    .icon {
      background-color: ${({ theme }) => theme.colors.white};
      img, svg {
        filter: invert(30%) sepia(96%) saturate(1484%) hue-rotate(171deg) brightness(97%) contrast(101%);
      }
    }
  }

  &.dark {
    color: ${({ theme }) => theme.colors.primaryTextColor};
  }
`

export const IconArea = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 auto 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
`

export const SkeletonHeadline = styled(Skeleton)`
  width: 150px;
  height: 40px;
`

import styled from 'styled-components'
import { Styles } from '@fortawesome/fontawesome-svg-core'
import { device } from 'src/theme/theme'

interface SpaceIcon {
  isMarginL?: boolean
}

export interface DefaultProps {
  backgroundColor: string
  color: string
  border?: string
  borderRadius?: string
  borderLeft?: string
  borderBottomWidth?: string
}

export interface ApplyCssProps {
  css: Styles
}

export type WrapperProps = DefaultProps | ApplyCssProps

export const Wrapper = styled.div<WrapperProps & { disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.small};
  border-radius: 6px;

  ${p =>
    'css' in p
      ? p.css
      : {
          'background-color': p.backgroundColor,
          border: p.border,
          color: p.color,
          'border-radius': p.borderRadius,
          'border-left-width': p.borderLeft,
          'border-bottom-width': p.borderBottomWidth,
        }}

  ${({ disabled, theme }) =>
    disabled && {
      'background-color': theme.colors.palette.neutral400,
    }}
`

export const RightAccessoryWrapper = styled.span<SpaceIcon>`
  margin-left: ${({ isMarginL, theme }) => addSpacing(isMarginL, theme.spacing.extraSmall)};
  display: flex;
`

export const LeftAccessoryWrapper = styled.span<SpaceIcon>`
  margin-right: ${({ isMarginL, theme }) => addSpacing(isMarginL, theme.spacing.extraSmall)};
  display: flex;
`

export const StyledLink = styled.a`
  display: inline-flex;

  ${({ theme }) => ({ ...theme.size.xxs })};

  @media ${device.tablet} {
    ${({ theme }) => ({ ...theme.size.xs })};
  }

  .secondaryBr {
    transition: all 0.5s linear;
  }

  .secondaryBr:hover,
  .secondaryBr:focus {
    background-color: ${p => p.theme.colors.secondaryBackgroundColor} !important;
    color: #fff;
  }
`

export const StyledButton = styled.button`
  display: inline-flex;
  transition: all 0.2s;
  background-color: transparent;

  ${({ theme }) => ({ ...theme.size.xxs })};

  @media ${device.tablet} {
    ${({ theme }) => ({ ...theme.size.xs })};
  }

  &:disabled {
    cursor: auto;
  }
`

function addSpacing(isMarginL = true, spacing: string) {
  return isMarginL ? spacing : 0
}

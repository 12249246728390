import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const GroupButtons = styled.div<{ removeMarginButton?: boolean, gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    justify-content: flex-end;
  }

  a + a, a + button  {
    margin-left: 0.5rem;
  }

  a, button {
    div {
      ${({ theme }) => ({ ...theme.typography.primary.normal })};
      ${({ theme }) => ({ ...theme.size.xs })};
      span {
        ${({ theme }) => ({ ...theme.size.xs })};
      }
    }

    :hover {
      filter: brightness(90%);
    }
  }

  .button-login {
    font-size: 0;
    margin: ${({ removeMarginButton }) => removeMarginButton ? '0.5rem 0' : '0.5rem'};

    @media ${device.tablet} {
      ${({ theme }) => ({ ...theme.size.xxs })};
    }

    @media ${device.desktop} {
      ${({ theme }) => ({ ...theme.size.xs })};
    }

    & > span {
      margin-right: 0;

      @media ${device.tablet} {
        margin-right: 8px;
      }
    }
  }

  .button-home {
    font-size: 0;
    margin: ${({ removeMarginButton }) => removeMarginButton ? '0.5rem 0' : '0.5rem'};

    @media ${device.tablet} {
      ${({ theme }) => ({ ...theme.size.xs })};
    }

    & > span {
      margin-right: 0;
      ${({ theme }) => ({ ...theme.size.md })};

      @media ${device.tablet} {
        ${({ theme }) => ({ ...theme.size.xs })};
      }

      @media ${device.tablet} {
        margin-right: 8px;
      }
    }
  }
`

import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TxKeyPath } from 'src/i18n'
import { colors } from 'src/theme/colors'
import { size } from 'src/theme/size'
import { getImageUrl } from 'src/utils/getImageUrl'
import { StyledTitle, Wrapper, IconArea, SkeletonHeadline } from './styles'

export type TitleSize = keyof typeof size

interface Props {
  tx: TxKeyPath | string
  borderColor?: string
  size?: TitleSize
  hasBorder?: boolean
  justify?: string
  textAlign?: string
  borderMargin?: string
  hasIcon?: boolean
  marginTitle?: string
  iconSource?: string
  Icon?: JSX.Element
  theme?: string
  search?: string
  marginMobile?: string
}

function TitleComponent(props: Props) {
  const { tx, hasBorder = true, hasIcon = false, theme = 'dark', search = '' } = props
  const { t } = useTranslation()

  const icon = hasIcon ? (
    <IconArea className="icon">
      {props.iconSource ? (
        <img src={getImageUrl(props.iconSource, 45, 45)} alt={t(tx)} />
      ) : (
        props.Icon
      )}
    </IconArea>
  ) : null

  return (
    <Wrapper justify={props?.justify || 'center'}>
      <StyledTitle
        hasBorder={hasBorder}
        size={props?.size || 'xl'}
        borderColor={props?.borderColor || colors.palette.secondary600}
        textAlign={props?.textAlign || 'center'}
        borderMargin={props?.borderMargin || 'extraLarge'}
        marginTitle={props?.marginTitle || '0'}
        marginMobile={props?.marginMobile || '0'}
        className={theme}
      >
        {icon}
        {tx ? <span>{t(tx)} <strong>{search}</strong></span> : <SkeletonHeadline />}
      </StyledTitle>
    </Wrapper>
  )
}

export const Title = memo(TitleComponent)

import styled from 'styled-components'
import React from 'react'
import theme from 'src/theme/theme'

interface LoadingProps {
  width: string
  height: string
  border: string
  borderTop: string
}

const Loading = styled.div<LoadingProps>`
  width: ${({ width }) => width} !important;
  height: ${({ height }) => height};
  border: ${({ border }) => border};
  border-top: ${({ borderTop }) => borderTop};
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

interface Props {
  preset?: string
}

const LoadingSpinner: React.FC<Props> = props => {
  const presetStyles = presets[props?.preset || 'small']
  return <Loading {...presetStyles} />
}

interface PresetProps {
  [key: string]: LoadingProps
}

const presets: PresetProps = {
  small: {
    width: '20px',
    height: '20px',
    border: `3px solid ${theme.colors.white}88`,
    borderTop: `3px solid ${theme.colors.secondaryBackgroundColor}99`,
  },

  medium: {
    width: '30px',
    height: '30px',
    border: `4px solid ${theme.colors.white}88`,
    borderTop: `4px solid ${theme.colors.secondaryBackgroundColor}99`,
  },
}

export default LoadingSpinner

import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const Backdrop = styled.div`
  display: none;
  &.active {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 99;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: block;
  }
`;

export const Profile = styled.div`
  grid-area: profile;
  display: flex;
  justify-content: end;
  position: relative;

  img {
    width: 100%;
    border-radius: 50%;
  }
`

export const ProfileContent = styled.div<{ visibleNameMobile: boolean }>`
  display: flex;
  justify-content: end;
  align-items: center;

  .img-content {
    display: flex;
    cursor: pointer;
    margin: ${({ visibleNameMobile }) => (visibleNameMobile ? '0.5rem 1rem 0.5rem 0' : '0.5rem 0')};

    @media ${device.tablet} {
      margin: 0.5rem 1rem 0.5rem 0;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }

  img {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .area-name {
    display: ${({ visibleNameMobile }) => (visibleNameMobile ? 'none' : 'flex')};
    align-items: center;
    cursor: pointer;
    .icon {
      margin-left: 0.2rem;
      @media ${device.mobileL} {
        display: inline-block;
      }
    }
  }
  span {
    margin-left: 0.5rem;
    ${({ theme }) => theme.size.sm};
    @media ${device.mobileL} {
      display: inline-block;
    }
  }
`

export const Nav = styled.nav<{ visibleNameMobile: boolean }>`
  position: absolute;
  top: 50px;
  right: ${({ visibleNameMobile }) => (visibleNameMobile ? '25px' : '0')};

  z-index: 999;
  margin-right: 1rem;

  @media ${device.mobileL} {
    top: 40px;
    right: 1rem;
  }

  @media ${device.tablet} {
    top: 50px;
    right: 1rem;
  }

  @media (min-width: 769px) and (max-width: 950px) {
    right: 0;
  }

  @media ${device.laptop} {
    top: 55px;
    right: 0;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }

  max-width: 0;
  max-height: 0;
  padding: 0;
  transition: all 0.3s ease 0s;
  overflow: hidden;

  &.opened {
    width: 150px;
    max-width: 356px;
    max-height: 356px;
  }

  .menu-wrapper {
    width: 100%;
    height: 100%;
    padding: 1rem 0;
    position: relative;

    ::before {
      content: '';
      position: absolute;
      z-index: 333333;
      top: -14px;
      right: 0;
      display: block;
      margin: 10px auto 0;
      border: 10px solid;
      border-color: transparent transparent ${({ theme }) => theme.colors.palette.neutral300}
        transparent;
    }
  }

  ul {
    list-style: none;
    border-radius: 0.3rem 0 0.3rem 0.3rem;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.palette.neutral300};

    li + li {
      border-top: 1px solid ${({ theme }) => theme.colors.palette.neutral450};
    }

    li {
      a,
      .exit {
        display: flex;
        align-items: center;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        background: ${({ theme }) => theme.colors.palette.neutral300};
        color: ${({ theme }) => theme.colors.secondaryTextColor};
        cursor: pointer;

        span {
          ${({ theme }) => theme.size.xxs}
          margin-left: 0.5rem;
        }

        :hover {
          color: ${({ theme }) => theme.colors.secondaryBackgroundColor};
        }
      }
    }
  }
`

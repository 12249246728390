import React, { FC } from 'react'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { BsGearFill, BsPersonCircle } from 'react-icons/bs'
import { ImExit } from 'react-icons/im'
import { IoIosArrowDown } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/store/rootReducers'
import { Button } from 'src/components/Common/Button'
import { FaUserAlt, FaMusic } from 'react-icons/fa'
import { GroupButtons } from 'src/components/Common/GroupButtons/styles'
import { logout } from 'src/store/user'
import { apiPrivate } from 'src/service/api/api.private'
import { getImageUrl } from 'src/utils/getImageUrl'
import { conditionRenderImg } from 'src/utils/conditionRenderImg'
import { Backdrop, Nav, Profile, ProfileContent } from './styles'

interface Props {
  visibleNameMobile?: boolean
  removeMarginButton?: boolean
  gridAreaButtons?: string
}

const ProfileHeader: FC<Props> = ({
  visibleNameMobile = false,
  removeMarginButton = false,
  gridAreaButtons = 'profile',
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [openedMenu, setOpenedMenu] = React.useState(false)
  const profile = useSelector((state: State) => state.user.profile)
  const isAuthenticated = useSelector((state: State) => state.user.isAuthenticated)

  async function handleLogout() {
    const res = await apiPrivate.logout()
    if (res.kind === 'ok') {
      dispatch(logout())
    }
  }

  if (isAuthenticated === false) {
    return (
      <GroupButtons removeMarginButton={removeMarginButton} gridArea={gridAreaButtons}>
        <Button
          type="button"
          isLink
          href="/assinar"
          labelTx="header.register"
          preset="danger"
          className="button-login"
          LeftAccessory={<FaMusic className="icon-login" />}
        />
        <Button
          type="button"
          isLink
          href="/login"
          labelTx="header.login"
          preset="secondary"
          className="button-login"
          LeftAccessory={<FaUserAlt className="icon-login" />}
        />
      </GroupButtons>
    )
  }

  return (
    <>
      <Backdrop onClick={() => setOpenedMenu(false)} className={openedMenu ? 'active' : ''} />
      <Profile id="profile">
        {profile ? (
          <>
            <ProfileContent visibleNameMobile={visibleNameMobile}>
              <div className="img-content" onClick={() => setOpenedMenu(!openedMenu)}>
                <img
                  src={
                    conditionRenderImg(profile.profilePhoto)
                      ? getImageUrl(profile.profilePhoto, 40, 40)
                      : 'https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224793/avatar_zrtqux.png'
                  }
                  alt="Avatar"
                />
                <div className="area-name">
                  <span>{profile?.name.split(' ')[0]}</span>
                  <IoIosArrowDown className="icon" />
                </div>
              </div>
            </ProfileContent>

            <Nav visibleNameMobile={visibleNameMobile} className={openedMenu ? 'opened' : ''}>
              <div className="menu-wrapper">
                <ul>
                  <li>
                    <Link legacyBehavior passHref href="/perfil">
                      <a>
                        <BsPersonCircle />
                        <span>{t('profile.menu.profile')}</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior passHref href="/perfil/alterar-dados">
                      <a>
                        <BsGearFill />
                        <span>{t('profile.menu.edit')}</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <div onClick={() => handleLogout()} className="exit">
                      <ImExit />
                      <span>{t('profile.menu.exit')}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </Nav>
          </>
        ) : null}
      </Profile>
    </>
  )
}
export default ProfileHeader

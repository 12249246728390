import React from 'react'
import { getProfileRequest } from 'src/store/user'
import { useDispatch } from 'react-redux'

function useGetProfile() {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getProfileRequest())
  }, [])
}

export default useGetProfile
